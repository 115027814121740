import { createWebHistory, createRouter } from 'vue-router';
import { authGuard } from './auth-guard';

const routes = [
	{ path: '/:catchAll(.*)', redirect: { name: 'Login' } },
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/components/layout/AuthLayout.vue')
	}, {
		path: '/admin',
		name: 'AppLayout',
		beforeEnter: authGuard,
		component: () => import('@/components/layout/AppLayout.vue'),
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: () => import('@/components/pages/Dashboard.vue')
			}, {
				path: '/entities',
				name: 'Entities',
				component: () => import('@/components/pages/entities.vue')
			},
			{
				path: '/upstreams',
				name: 'Upstreams',
				component: () => import('@/components/pages/upstreams.vue')
			},
			{
				path: '/rooms',
				name: 'Rooms',
				component: () => import('@/components/pages/rooms.vue')
			},
			{
				path: '/bookings',
				name: 'Bookings',
				component: () => import('@/components/pages/bookings.vue')
			},
			{
				path: '/subscriptions',
				name: 'Subscriptions',
				component: () => import('@/components/pages/subscriptions.vue')
			},
			{
				path: '/subscriptions_old',
				name: 'Subscriptions Old',
				component: () => import('@/components/pages/subscriptions_old.vue')
			},
			{
				path: '/orders',
				name: 'Orders',
				component: () => import('@/components/pages/orders.vue')
			},
			{
				path:'/spaRequest',
				name:'spaRequest',
				component:()=>import('@/components/pages/spaRequest.vue')
			},
			{
				path:'/service_request',
				name:'Service Requests',
				component:()=>import('@/components/pages/serviceRequests.vue')
			},
			{
				path: '/allowedapps',
				name: 'Allowed Apps',
				component: () => import('@/components/pages/allowedApps.vue')
			},
			{
				path: '/zeeLive',
				name: 'Zee Live',
				component: () => import('@/components/pages/zeeLive.vue')
			},
			{
				path: '/wallet',
				name: 'Wallet',
				component: () => import('@/components/pages/wallet.vue')
			},
			{
				path: '/wallet_balance',
				name: 'Wallet Balance',
				component: () => import('@/components/pages/walletBalances.vue')
			},
			{
				path: '/invoices',
				name: 'Invoices',
				component: () => import('@/components/pages/invoices.vue')
			},
			{
				path: '/food',
				name: 'Food',
				component: () => import('@/components/pages/food.vue')
			},
			{
				path: '/guestService',
				name: 'Guest Service',
				component: () => import('@/components/pages/guestService.vue')
			},
			{
				path: '/places',
				name: 'Place',
				component: () => import('@/components/pages/places.vue')
			},
			{
				path: "/spa",
				name: "spa",
				component: () => import("@/components/pages/spa.vue"),
			},
			{
				path: '/restaurants',
				name: 'Restaurants',
				component: () => import('@/components/pages/restaurants.vue')
			},
			{
				path: '/categories',
				name: 'Categories',
				component: () => import('@/components/pages/categories.vue')
			},
			{
				path: '/stb_home',
				name: 'Stb Home',
				component: () => import('@/components/pages/NewStbHome.vue')
			},
			{
				path: '/communicationgroups',
				name: 'Communication Groups',
				component: () => import('@/components/pages/communicationGroup.vue')
			},
			{
				path: '/servicegroup',
				name: 'Service Groups',
				component: () => import('@/components/pages/serviceGroup.vue')
			},
			{
				path: '/whatsapp',
				name: 'WhatsApp',
				component: () => import('@/components/pages/WhatsApp.vue')
			},
			{
				path: '/monitor',
				name: 'Monitor',
				component: () => import('@/components/pages/monitoring.vue')
			},
			{
				path: '/logo',
				name: 'LogoSetting',
				component: () => import('@/components/pages/LogoSetting.vue')
			},
			{
				path: '/profile',
				name: 'Profile',
				component: () => import('@/components/pages/updateProfile.vue')
			},
			{
				path: '/aboutus',
				name: 'Aboutus',
				component: () => import('@/components/pages/aboutus.vue')
			},
			{
				path: '/modules',
				name: 'Modules',
				component: () => import('@/components/pages/Modules.vue')
			}, {
				path: '/privileges',
				name: 'Privileges',
				component: () => import('@/components/pages/Privilage.vue')
			}, {
				path: '/role',
				name: 'Role',
				component: () => import('@/components/pages/Role.vue')
			},
			{
				path: '/state',
				name :'State',
				component: () => import('@/components/pages/State.vue')
			},
			{
				path:'/login',
				name:'Logout',
				component:()=>import('@/components/pages/Logout.vue')
			},
			{
				path: '/endpoint',
				name: 'Endpoint',
				component: () => import('@/components/pages/endpoint.vue')
			},
			{
				path: '/server',
				name: 'Server',
				component: () => import('@/components/pages/server.vue')
			},
			{
				path: '/token',
				name: 'Token',
				component: () => import('@/components/pages/token.vue')
			},
			{
				path: '/report',
				name: 'Report',
				component: () => import('@/components/pages/report.vue')
			},

			{
               path: '/version',
               name: 'Version',
               component:() => import('@/components/pages/version.vue')
			},
			{
				path: '/defaultversion',
				name: 'Default Version',
				component: () => import('@/components/pages/defaultversion.vue')
			},
			{
				path: '/subservicegroup',
				name: 'Sub Service Groups',
				component: () => import('@/components/pages/subServiceGroup.vue')
			},
			{
				path: '/user',
				name: 'User',
				component: () => import('@/components/pages/user.vue')
			},
			{
				path: "/edgetemplate",
				name: "Edge Template",
				component: () => import("@/components/pages/edgetemplate.vue")
			},
			{
				path: "/manageEdges",
				name: "Manage Edges",
				component: () => import("@/components/pages/manageEdges.vue")
			},
			{
				path: "/manage/microsoft/:id",
				name: "Micro Soft",
				component: () => import("@/components/pages/microsoft.vue")
			},
			{
				path:'/spaRequest',
				name:'spaRequest',
				component:()=>import('@/components/pages/spaRequest.vue')
			},
			{
				path: '/hotelvideos',
				name: 'Hotel Videos',
				component: () => import('@/components/pages/hotelvideos.vue')
			},
			{
				path:'/tag',
				name:'Tag',
				component:()=>import('@/components/pages/Tag.vue')
			},
			{
				path: '/ProviderLanguage',
				name: 'ProviderLanguage',
				component:()=>import('@/components/pages/Language.vue')
			},
			{
				path: '/ProviderGenres',
				name: 'ProviderGenres',
				component:()=>import('@/components/pages/Genres.vue')
			},
			{
				path: "/providerPackages",
				name: "Provider Packages",
				component: () => import("@/components/pages/ProviderPackages.vue")
			},
			{
				path: "/providerChannels",
				name: "Provider Channels",
				component: () => import("@/components/pages/ProviderChannels.vue")
			},
			{
				path: "/bundles",
				name: "Bundles",
				component: () => import("@/components/pages/Bundles.vue")
			},
			{
				path: "/orgs",
				name: "Orgs",
				component: () => import("@/components/pages/Orgs.vue")
			},
			{
				path: "/operatorSettings",
				name: "Operator Settings",
				component: () => import("@/components/pages/OperatorSettings.vue")
			},
			{
				path:'/vendor',
				name:'Vendor',
				component:()=>import('@/components/pages/Vendor.vue')
			},
			{
				path:'/inventory',
				name:'Inventory',
				component:()=>import('@/components/pages/Inventory.vue')
			},
			{
				path:'/provider',
				name:'Provider',
				component:()=>import('@/components/pages/Provider.vue')
			},
			{
				path:'/cloud_tv',
				name:'Cloud Tv',
				component:()=>import('@/components/pages/CloudTv.vue')
			},
			{
				path: '/monitoringlogs',
				name: 'MonitorLogs',
				component: () => import('@/components/pages/monitoringlogs.vue')
			},
			{
				path: '/qr_transaction',
				name: 'QR Transaction',
				component: () => import('@/components/pages/QrTransaction.vue')
			},
			{
				path: '/whitelistedstb',
				name: 'Whitelisted STB',
				component: () => import('@/components/pages/whitelistedSTB.vue')
			},
			{
				path: '/org_apps',
				name: 'Org Apps',
				component: () => import('@/components/pages/OrgApps.vue')
			},
			{
				path: '/cdn',
				name: 'Cdn',
				component: () => import('@/components/pages/cdn.vue')
			},
			{
				path: '/cdnsetting',
				name: 'Cdn Setting',
				component: () => import('@/components/pages/cdnSetting.vue')
			},
			{
				path: '/cdn_patch',
				name: 'Cdn Patch',
				component: () => import('@/components/pages/CdnPatch.vue')
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(),
	hash: false,
	routes,
});

export default router;