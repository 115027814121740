import config from '@/router/config';
import axios from 'axios';
var Method = {
    'POST' : 'post',
    'PUT'  : 'put'
}
export async function fetch(type, api, payload, loadingPlugin, toasterPlugin) {
    let loader = loadingPlugin.show({ width: 40, height: 40 });
    try {
        const response = await axios[Method[type]](config.host + api, payload);
        loader.hide();
        toasterPlugin.success(response.data);
        return 1;
    } catch (error) {
        loader.hide();
        const errorMessage = error.response ? error.response.data : 'An error occurred.';
        toasterPlugin.error(errorMessage);
        return 0;
    };
};
