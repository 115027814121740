<template>
    <div v-if="this.device">
        <VueSidePanel
            v-model="showDelete"
            height="130px"
            hide-close-btn
            side="bottom"
            :panel-duration="400"
            no-close
            class="deleteModalM"
            :overlay-opacity="0.9"
        >
            <br><br>
            <center>
                <div>{{ deleteMessge }}</div><br>
                <div class="pt-4">
                    <button class="deleteCancelM" @click.prevent="sendResponse()">Not Now</button>
                    <button class="deleteConfirmM" @click.prevent="confirmDelete()">Yes, Delete</button>
                </div>
            </center>
        </VueSidePanel>
    </div>
    <div v-else>
        <va-modal
            class="popup"
            v-model="showDelete"
            title="Delete Confirmation"
            :message="deleteMessge"
            okText="Yes, Delete"
            cancelText="Not Now"
            @ok="confirmDelete()"
            @cancel="sendResponse()"
            blur 
        />
    </div>
</template>

<script>
    export default{
        props:{
            deleteMessge: String,
            deleteApi: String,
            deleteId: String
        },
        data(){
            return{
                showDelete: true,
                device: this.$globalDevice == 'mobile' ? true : false
            }
        },
        methods: {
            confirmDelete(){
                const response = this.$del(this.deleteApi+'/'+this.deleteId, this.$loading, this.$toast)
                if(response) {
                    this.$emit('response-event', {'code':200,'data':null});
                }else this.$emit('response-event', {'code':500,'data':null});
            },
            sendResponse(){
                this.showDelete = false;
                this.$emit('response-event', {'code':404,'data':null});
            }
        }
    }
</script>