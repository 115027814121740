<template >
    <va-card class="card-view" @click.prevent="openFunction()" :style="{ 'border' : this.error ? 'red solid 1px' : (this.disabled) ? '#c1c0c0 solid 1px' : 'grey solid 1px' }">
        <div class="container inside-view">
            <div>
                <div v-if="!afterSelectedFlag"><span class="placeholder-view">{{ this.placeholder }}</span></div>
                <div v-else>
                    <span 
                        class="placeholder-view"
                        :style="{ 'color' : this.disabled ? '#c1c0c0' : 'black'}">
                        {{ this.afterSelectedValues.join(', ') }}
                    </span>
                </div>
            </div>
            <div><i class="fa fa-angle-down icon-view"></i></div>
        </div>
    </va-card>
    <va-card v-if="closeTag" id="openCard">
        <div v-if="this.searchable">
            <input
                class="search-input"
                v-model="search_term"
                @input="searchOption(this.search_term)"
                placeholder="Search..."
            />
        </div>
        <div class="option-card-view" style="::-webkit-scrollbar{ display: none !important; scrollbar-width: none; }">
            <div class="mt-3" v-for="opt in filteredOptions">
                <p
                    @click.prevent="seletionOption(opt)"
                    class="option-view"
                    :style="{ 'color': opt.select ? '#4399E2' : '#404040' }">
                    {{ opt[this.label] }}
                </p>
            </div>
        </div>
    </va-card>
</template>

<script>
import { search } from '@/module/searchComponent';
export default {
    props: {
        options     : Array,
        label       : String,
        multiple    : Boolean,
        modelValue  : [String, Array],
        error       : Boolean,
        searchable  : Boolean,
        disabled    : Boolean,
        placeholder : String
    },
    created(){
        this.initCall();
        this.closeTag = false;
    },
    data(){
        return {
            selectedValues: this.value,
            placeholder: this.placeholder,
            closeTag: false,
            afterSelectedValues: [],
            afterSelectedFlag: false,
            search_term: null,
            backupOptions: [],
            backupMultiselectValues: []
        }
    },
    mounted(){
        document.addEventListener('click', this.hideContent)
    },
    computed:{
        filteredOptions() {
            return this.search_term ? search(this.search_term, this.options) : this.options;
        }
    },
    updated(){
        if(!this.modelValue){
            this.afterSelectedValues = [];
            this.afterSelectedFlag = false;
        }else{
            this.afterSelectedFlag = true;
            if(!this.multiple){
                if(!this.afterSelectedValues.includes(this.modelValue[this.label])) this.afterSelectedValues.push(this.modelValue[this.label]);
                this.options.map(iter => {
                    if(iter[this.label] == this.modelValue[this.label]) iter.select = true;
                    return iter;
                });
            }else{
                if(!this.modelValue.length) return;
                this.modelValue.map(op => {
                    if(!this.afterSelectedValues.includes(op[this.label])) this.afterSelectedValues.push(this.op[this.label]);
                    this.options.map(iter => {
                        if(iter[this.label] == this.afterSelectedFlag.includes(iter[this.label])) iter.select = true;
                        return iter;
                    });
                });
            };
            if(!this.afterSelectedValues.length) this.afterSelectedFlag = false;
        };
    },
    methods: {
        seletionOption(opt){
            if(!this.multiple) {
                this.closeTag = false;
                this.initCall();
                this.search_term = null;
                this.afterSelectedValues = [];
            };
            opt.select = !opt.select;
            if(this.multiple){
                const value = opt[this.label];
                var index = this.backupMultiselectValues.indexOf(value)
                if(index !== -1) this.backupMultiselectValues.splice(index, 1);
                else this.backupMultiselectValues.push(value);

                const datas = [];
                this.options.map((iter, i) => {
                    if(this.backupMultiselectValues.includes(iter[value])) datas.push(iter);
                    if(i+1 == this.options.length) {
                        this.selectedValues = datas;
                        this.$emit('select-event', this.selectedValues);
                    }
                });
            }else {
                this.selectedValues = opt;
                this.$emit('select-event', this.selectedValues);
            }
        },
        initCall(){
            return this.options.map(iter => {
                iter.select = false;
                return iter;
            })
        },
        searchOption(term){},
        openFunction(){
            if(this.disabled) return;
            else this.closeTag = !this.closeTag;
        },
        hideContent(event){
            if(event.target.className == 'container inside-view' || event.target.className == 'option-view' || event.target.className == 'search-input') return;
            this.closeTag = false;
        }
    },
};
</script>

<style>
    .card-view {
        width: 100%;
        margin-top: 2px;
        border: grey solid 1px;
        border-radius: 8px;
        box-shadow: none !important;
        position: relative;
        cursor: pointer;
    }

    .inside-view {
        padding: 8px 15px 8px 12px;
    }

    .placeholder-view {
        font-size: 12px;
        color: grey;
    }

    .icon-view {
        font-size: 13px;
        margin-top: 3px;
    }

    .option-view {
        padding: 4px 15px;
        font-size: 14px;
    }

    .option-card-view {
        z-index: 9 !important;
        overflow: scroll;
        overflow-x: hidden;
        max-height: 15rem;
        height: fit-content;
        background-color: #f8f8f8 !important;
        position: absolute;
        width: 100%;
    }

    .option-view:hover{
        background-color: #D2E9FD;
        cursor: pointer;
    }

    .search-input {
        width: 100%;
        height: 35px;
        border: none;
        border-bottom: gray solid 1px;
        padding: 12px;
        font-size: 14px;
    }
</style>
