import config from '@/router/config';
import axios from 'axios';

export async function del(api, loadingPlugin, toasterPlugin) {
    let loader = loadingPlugin.show({ width: 40, height: 40 });
    try {
        const response = await axios.delete(config.host + api);
        loader.hide();
        toasterPlugin.success(response.data);
        return 1;
    } catch (error) {
        loader.hide();
        const errorMessage = error.response ? error.response.data : 'An error occurred.';
        toasterPlugin.error(errorMessage);
        return 0;
    }
}